@tailwind base;
@tailwind components;
@tailwind utilities;
// Plugin CSS
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~animate.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "node_modules/react-modal-video/scss/modal-video.scss";

// Init main SCSS File
@import "/public/assets/scss/style.scss";
