/*
Theme Name: TransPro - Transport & Logistics Service Figma Template
Theme URI: http://wowtheme.com/html/transport
Author: Wowtheme
Author URI: http://wowtheme.com
Description: TransPro - Transport & Logistics Service Figma Template
Version: 2.0.0
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Global
    ## Global
    ## Mixins
    ## Theme
    ## Variables
    ## Common

# Sections
    ## Nav Bar
    ## Banner
    ## Brand Area
    ## Footer
    ## Widgets

# Elements
    ## accordion
    ## filter
    ## apartment
    ## blog
    ## city
    ## feature
    ## gallery
    ## intro
    ## main-search
    ## pricing
    ## service
    ## team
    ## user-list

--------------------------------------------------------------*/

//== Global Design
@import "global/_mixins";
@import "global/_variables";
@import "global/_global";
@import "global/_common";
@import "global/_animate";

//== Pages
//== Sections
@import "sections/_section-title";
@import "sections/_navbar";
@import "sections/_search";
@import "sections/_banner";
@import "sections/_content";
@import "sections/_service";
@import "sections/_widgets";
@import "sections/_blog";
@import "sections/_sectionbg";
@import "sections/_footer";
@import "sections/_responsive";

.collapse {
  visibility: visible !important;
}

a {
  pointer-events: none !important;
}
